
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({})
export default class BtnLoading extends Vue {
  isLoading: boolean = false;
  startLoading() {
    this.isLoading = true;
  }
  stopLoading() {
    this.isLoading = false;
  }
}
